
            @import "@/assets/sass/vars.portal.scss";
          












































































































































.mg-toc {
    display: flex; 
    flex-direction: row;
    padding-right: 30px;
    
    &__navi {
        flex: 0 0 250px;
        
    }

    &__content {
        flex: 1 1;
        padding-left: 40px;
        border-left: 1px solid $border-color;
    }

    .mg-toc__navi {
        .router-link-exact-active {
            color: #789e6b;
            font-weight: 700;
        }  
    } 

    img[src*="#small"] {
    max-width:300px;
    display: block;
    }

    img[src*="#medium"] {
        max-width:500px;
        display: block;
    }
    
    img[src*="#large"] {
        max-width:800px;
        display: block;
    }
}
